import React, {useState, useEffect, useRef} from 'react';
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";
import './App.css';
import {FaGithub, FaInstagram, FaLinkedin} from "react-icons/fa";
import {SiMinutemailer} from "react-icons/si";

const projects = [
    {
        title: "Smoothie poster",
        role: "Graphic Designer",
        technologies: "Adobe Photoshop",
        images: ["/sh-poster.jpg"],
    },
  {
    title: "Suomen Ortoosipalvelu",
    role: "Illustrator",
    technologies: "Adobe Photoshop, Linearity Curve",
    images: [
      "/js2.png",
      "/js1.png",
      "/js3.png",
      "/js4.png",
    ],
  },
    {
        title: "Social network platform",
        role: "UI/UX Designer, Frontend Developer",
        technologies: "Figma, TypeScript, Next.js, React, TailwindCSS, DaisyUI, SQLite",
        images: [
            "/f1.png",
            "/f2.jpeg",
            "/f3.jpeg",
            "/f4.jpeg",
            "/f5.jpeg",
            "/f6.jpeg",
            "/f7.jpeg",
        ],
    },
    {
        title: "EWExorcism",
        role: "Concept Artist, Game Designer, 2D-Artist",
        technologies: "Linearity Curve, Adobe Photoshop",
        images: [
            "/ewe1.jpeg",
            "/ewe2.mp4",
            "/ewe3.png",
            "/ewe4.png",
            "/ewe5.png",
        ],
    },
    {
        title: "Artifact film",
        role: "Concept Artist, 3D-Modeler",
        technologies: "Autodesk Maya, Unreal Engine",
        images: [
            "/a1.jpeg",
        ],
    },
    {
        title: "Personal project",
        role: "Concept Artist, 3D-Modeler, Texture Artist, Lighting Artist",
        technologies: "Autodesk Maya, Adobe Photoshop, Marmoset Toolbag, Substance Painter",
        images: [
            "/gu1.jpg",
            "/gu2.jpg",
            "/gu3.jpg",
            "/gu4.jpg",
        ],
    },
];

function ProjectCarousel({ images }) {
  const [currentImage, setCurrentImage] = useState(0);

  const nextImage = () => {
    setCurrentImage((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImage((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };
    const currentItem = images[currentImage];
    const isVideo = currentItem.endsWith(".mp4") || currentItem.endsWith(".webm") || currentItem.endsWith(".ogg");


    return (
      <div className="relative">
        <div className="mx-12">
            {isVideo ? (
                <video
                    src={currentItem}
                    controls
                    className="rounded-lg shadow-lg lg:max-w-2xl h-auto"
                >
                    Your browser does not support the video tag.
                </video>
            ) : (
                <img
                    src={currentItem}
                    alt=""
                    className="rounded-lg shadow-lg lg:max-w-2xl h-auto"
                />
            )}
        </div>

        {images.length > 1 && (
            <div className="absolute top-1/2 left-0 right-0 flex justify-between items-center">
                <button
                    onClick={prevImage}
                    className="text-4xl p-2 text-gray-400 hover:text-gray-600">
                    <IoIosArrowBack/>
                </button>
                <button
                    onClick={nextImage}
                    className="text-4xl p-2 text-gray-400 hover:text-gray-600">
                    <IoIosArrowForward/>
                </button>
            </div>
        )}
      </div>
  );
}

/*
function Description() {
    return (
        <div className="absolute z-10 flex flex-col items-center justify-center text-center w-full max-w-2xl animated-fadeIn animated-slideRight">
            <p className="text-gray-400 text-lg font-semibold animated-fadeIn animated-slideRight">
                I am a designer and software developer with a passion for creating engaging and interactive experiences.
            </p>
            <p className="text-gray-400 text-lg font-semibold animated-fadeIn animated-slideRight">
                I have experience in game design, web development, and 3D modeling.
            </p>
        </div>
    );
}
 */

function ProjectSection({ project, index }) {
    const ref = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(ref.current); // Stop observing after animation triggers
                }
            },
            { threshold: 0.1 } // Adjust threshold for when the animation starts
        );

        if (ref.current) observer.observe(ref.current);

        return () => observer.disconnect();
    }, []);

    return (
        <section
            ref={ref}
            className={`flex flex-col items-center w-full py-10 transform transition-all duration-700 ${
                isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
            } ${
                index % 2 === 0 ? "bg-gray-100" : "bg-background"}`}
        >
            <figure className="w-full flex flex-col lg:flex-row items-center justify-center lg:gap-7">
                <figcaption className="flex flex-col lg:flex-col-reverse gap-3 lg:text-left lg:w-72 mt-auto p-7 md:px-14 lg:p-0 w-full">
                    <h3 className="opacity-50 lg:text-end text-lg">{project.title}</h3>
                    <div className="flex flex-col lg:text-end gap-2 font-semibold">
                        <p className="text-sm"><span className="text-sm opacity-50">Role  •  </span>{project.role}</p>
                        <p className="text-sm"><span className="text-sm opacity-50">Technologies   • </span>{project.technologies}
                        </p>
                    </div>
                </figcaption>
                <ProjectCarousel images={project.images} />
            </figure>
        </section>
    );
}

function Home() {
    return (
      <div className="relative flex flex-col min-h-screen w-screen">
          <header className="relative flex justify-center items-center h-screen">
              <AnimatedName/>

          </header>
          {/* Projects */}
          <main className="flex flex-col items-center justify-center w-screen">
              {projects.map((project, index) => (
                  <ProjectSection key={index} project={project} index={index}/>
              ))}
          </main>

          <a href="mailto: kim@nat.ax"
             title={"Send an email"}
              className="fixed z-50 bottom-7 right-7 bg-gray-400 text-white rounded-full p-3 shadow-lg hover:bg-gray-600 text-3xl"
          >
              <SiMinutemailer />
          </a>

          <footer className="p-4 pt-52 text-center mt-auto">

              <p className="opacity-40"><span>&copy; {new Date().getFullYear()}</span> Natalia Kim</p>
          </footer>
      </div>
  );
}

function AnimatedName() {

    const links = [
        {icon: <FaLinkedin/>, url: "https://www.linkedin.com/in/nat-kim/"},
        {icon: <FaGithub/>, url: "https://github.com/nattikim"},
        {icon: <FaInstagram/>, url: "https://www.instagram.com/natti.kim/"},
    ];

  const [showSpecialization, setShowSpecialization] = useState(false);

    useEffect(() => {
        // Start the specialization animation after 1 second
        const specializationTimeout = setTimeout(() => {
            setShowSpecialization(true);
        }, 1000);

        return () => {
            clearTimeout(specializationTimeout);
        };
    }, []);


  return (
      <div className="z-20 absolute flex flex-col justify-center items-center w-full h-screen">
        {/* Name */}
        <h1 className="text-5xl">Natalia Kim</h1>

        {/* Specialization */}
        <h2
            className={`text-md mt-4 transition-all duration-1000 transform ${
                showSpecialization ? "translate-y-0" : "translate-y-10"
            } opacity-0 ${showSpecialization ? "opacity-100" : "opacity-0"}`}
        >
          Creative Designer, Software Developer
        </h2>
          <div className="flex gap-4 mt-4 ">
                {links.map((item, index) => {
                    return (
                        <a
                            key={index}
                            href={item.url}
                            target="_blank"
                            rel="noreferrer"
                            className={`text-xl text-gray-400 hover:text-gray-600 opacity-0 animate-fadeInDelayed${index + 1}`}
                        >

                            {item.icon}
                        </a>
                    );
                })}
          </div>

      </div>
  );
}

    export default Home;